/**
 *		- Trigger loading overlay
 *		- Load page via AJAX
 */
function lazyLoad(event) {
	if (dragging) return;

	var target = event.target.closest(".lazy-load");
	if (target) {
		event.preventDefault();

		// Trigger loading overlay
		document.body.classList.add("is-loading");

		// Get parent ID
		var parentID = target.parentNode.getAttribute("id");

		// Load page via AJAX
		var request = new XMLHttpRequest();
		request.onreadystatechange = function () {
			// XHR is done
			if (request.readyState === 4) {
				// XHR is successful
				if (request.status === 200) {
					target.remove();
					document.querySelector("#" + parentID).insertAdjacentHTML("beforeend", request.response.querySelector("#" + parentID).innerHTML);

					// Hide loading overlay
					document.body.classList.remove("is-loading");
				} else {
					// Problem with the XHR, alert and remove loading overlay
					alert("There was an error loading more, please try again.");
					document.body.classList.remove("is-loading");
				}
			}
		};

		// Open the request
		request.open("get", target.querySelector("a").getAttribute("href"), true);
		request.responseType = "document";
		request.send();
	}
}

document.addEventListener("DOMContentLoaded", function () {
	/**
	*		- AJAX request for next page of results when triggering a .lazy-load link
	*/
	document.addEventListener("click", lazyLoad);
	document.addEventListener("touchend", lazyLoad);
});