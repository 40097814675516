function toggleAccordion(event) {
	if (dragging) return;

	var target = event.target.closest(".accordion a.toggler");
	if (target) {
		event.preventDefault();

		target.classList.toggle("toggled");
	}
}

document.addEventListener("DOMContentLoaded", function () {
    document.addEventListener("click", toggleAccordion);
	document.addEventListener("touchend", toggleAccordion);
});