/**
 *		RD FORMS
 *		- Disable submit button on page load if using reCAPTCHA
 *		- Submit via AJAX
 *		- File inputs: display the name of selected file in the custom overlay
 */
// Disable submit button on page load if using reCAPTCHA
var rdForms = document.querySelectorAll(".rd-form[data-form-id]");
for (var i = 0; i < rdForms.length; i++) {
	// Disable submit button if reCAPTCHA exists
	if (rdForms[i].querySelector(".g-recaptcha") !== null) {
		rdForms[i].querySelector("#submit" + rdForms[i].getAttribute("data-form-id")).disabled = true;
	}
}

// Submit via AJAX
document.addEventListener("submit", function (event) {
	var target = event.target.closest(".rd-form[data-form-id]");
	if (target) {
		// Prevent normal form submission
		event.preventDefault();

		// Show loading overlay
		document.body.classList.add("is-loading");

		// Get form ID
		var dataFormID = target.getAttribute("data-form-id");

		// Disable submit button and change text
		var submitBtn = target.querySelector("#submit" + dataFormID);
		submitBtn.disabled = true;
		submitBtn.textContent = "working...";

		// Submit via AJAX
		var request = new XMLHttpRequest();
		request.onreadystatechange = function () {
			// XHR is done
			if (request.readyState === 4) {
				// XHR is successful
				if (request.status === 200) {
					// Either there was an error and we have the full form again
					if (request.response.querySelector("#" + target.id)) {
						target.parentNode.replaceChild(request.response.querySelector("#" + target.id), target);

						// If reCAPTCHA is present, re-instantiate and disable submit button
						if (document.querySelector("#" + target.id + " .g-recaptcha") !== null) {
							grecaptcha.render("g-recaptcha-" + dataFormID, {
								"sitekey": document.querySelector("#" + target.id + " .g-recaptcha").getAttribute("data-sitekey"),
								"callback": "enableSubmit" + dataFormID,
								"error-callback": "recaptchaError" + dataFormID,
								"expired-callback": "disableSubmit" + dataFormID
							});
							document.querySelector("#submit" + dataFormID).disabled = true;
						}
					}
					// Or there was no error and we have the success confirmation
					else if (request.response.querySelector("#" + target.id + "-success")) {
						target.parentNode.replaceChild(request.response.querySelector("#" + target.id + "-success"), target);
					}

					// Hide loading overlay
					document.body.classList.remove("is-loading");
				} else {
					// Problem with the XHR, alert the user and closing the loading overlay
					alert("There was an error submitting your information, please try again.");
					document.body.classList.remove("is-loading");
				}
			}
		};

		// Open the request using existing method and action, set responseType, and send
		request.open(target.getAttribute("method"), target.getAttribute("action"), true);
		request.responseType = "document";
		request.send(new FormData(target));
	}
});

// File inputs: display the name of selected file in the custom overlay
document.addEventListener("change", function (event) {
	var target = event.target.closest(".rd-form-file");
	if (target && target.type == "file") {
		var wrapperEl = target.parentNode;
		var overlayEl = wrapperEl.getElementsByClassName("overlay");
		var filenameEl = overlayEl[0].getElementsByClassName("filename");
		filenameEl[0].textContent = target.files[0].name;
	}
});