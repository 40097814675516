/**
 *		- Toggle current accessibility option menu, make sure others are hidden
 */
function toggleAccessibilityMenu(event) {
	if (dragging) return;

	var target = event.target.closest(".font-sizes > a, .contrast-modes > a");
	if (target) {
		event.preventDefault();
		event.stopPropagation();

		if (target.parentNode.classList.contains("font-sizes")) {
			document.querySelector(".contrast-modes > ul").style.display = "none";
		} else
			if (target.parentNode.classList.contains("contrast-modes")) {
				document.querySelector(".font-sizes > ul").style.display = "none";
			}
		var siblingMenu = target.nextElementSibling;
		if (window.getComputedStyle(siblingMenu).display == "none") {
			siblingMenu.style.display = "block";
		} else {
			siblingMenu.style.display = "none";
		}
	}
}

/**
 *		- Update html or body attribute to chosen font size or contrast mode, respectively
 *		- Set/update browser cookie
 */
function updateAccessibilityOption(event) {
	if (dragging) return;

	var target = event.target.closest("a[data-font-size], a[data-contrast-mode]");
	if (target) {
		// Prevent normal anchor tag behavior (page will reload as a fallback if javascript is disabled)
		event.preventDefault();

		// Update cookies and html/body element data attributes
		if (target.hasAttribute("data-font-size")) {
			var allLinks = document.querySelectorAll("a[data-font-size]:not([data-font-size=" + target.getAttribute("data-font-size") + "])");
			for (var link of allLinks) {
				link.classList.remove("active");
			}
			target.classList.add("active");
			document.documentElement.setAttribute("data-font-size", target.getAttribute("data-font-size"));
			document.cookie = "fontSize=" + target.getAttribute("data-font-size") + expires + "; path=/";

			updateBreakpointToDesktopNav();
		}
		else if (target.hasAttribute("data-contrast-mode")) {
			var allLinks = document.querySelectorAll("a[data-contrast-mode]:not([data-contrast-mode=" + target.getAttribute("data-contrast-mode") + "])");
			for (var link of allLinks) {
				link.classList.remove("active");
			}
			target.classList.add("active");
			document.body.setAttribute("data-contrast-mode", target.getAttribute("data-contrast-mode"));
			document.cookie = "contrastMode=" + target.getAttribute("data-contrast-mode") + expires + "; path=/";
		}

		updateWidthREMS();
		//target.parentNode.parentNode.style.display = "none";
	}
}

document.addEventListener("DOMContentLoaded", function () {
	/**
	 *		- Toggle font size or contrast mode menus
	 *		- Update font size or contrast mode and update browser cookie to remember setting
	 *		- Toggle accessibility toolbar and update browser cookie to remember setting
	 */
	// Toggle font size or contrast mode menu
	document.addEventListener("click", toggleAccessibilityMenu);
	document.addEventListener("touchend", toggleAccessibilityMenu);

	// Update font size or contrast mode
	document.addEventListener("click", updateAccessibilityOption);
	document.addEventListener("touchend", updateAccessibilityOption);

	// Toggle accessibility toolbar
	document.addEventListener("change", function (event) {
		var target = event.target.closest("[name=accessibilityToolbar]");
		if (target) {
			var accessibilityNav = document.querySelector("nav.accessibility");
			if (target.checked) {
				accessibilityNav.style.display = "block";
				accessibilityNav.classList.add("is-shown");
				document.cookie = "accessibilityToolbar=on" + expires + "; path=/";
			} else {
				accessibilityNav.style.display = "none";
				accessibilityNav.classList.remove("is-shown");
				document.cookie = "accessibilityToolbar=off" + expires + "; path=/";
			}
		}
	});
});