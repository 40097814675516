document.addEventListener("DOMContentLoaded", function () {
	var pageSections = document.querySelectorAll("main > article > section, main > section > article");
	pageSections.forEach(function(pageSection) {
		if (!isElementInViewport(pageSection)) {
			// Cover, contain, OR partial
			pageSection.classList.add("hidden");
		}
		document.addEventListener("scroll", function(event){
			if (isElementInViewport(pageSection)) {
				// Cover, contain, OR partial
				pageSection.classList.remove("hidden");
			}
		});
	});
});