// Get the width (in EMs/REMs) at which the full menu should be shown
let breakpointToDesktopNav;
updateBreakpointToDesktopNav();

/**
 *		UPDATE BREAKPOINT TO DESKTOP NAV
 *		- Updates the breakpointToDesktopNav variable when the fontSize option is changed
 */
function updateBreakpointToDesktopNav() {
	for (var i = 0; i < document.styleSheets.length; i++) {
		if (document.styleSheets[i].href !== null && document.styleSheets[i].href.indexOf("site.min.css") != -1) {
			var sheetRules = document.styleSheets[i].cssRules;
			for (var j = 0; j < sheetRules.length; j++) {
				if (sheetRules[j].selectorText == "#widthREMs") {
					var normal = sheetRules[j].style.width.replace(/[^0-9.]/g, '');
				} else if (sheetRules[j].selectorText == 'html[data-font-size="lg"] #widthREMs') {
					var large = sheetRules[j].style.width.replace(/[^0-9.]/g, '');
				} else if (sheetRules[j].selectorText == 'html[data-font-size="xl"] #widthREMs') {
					var xlarge = sheetRules[j].style.width.replace(/[^0-9.]/g, '');
				}
			}
		}
	}
	if (document.documentElement.getAttribute("data-font-size") == "xl") {
		breakpointToDesktopNav = xlarge;
	} else if (document.documentElement.getAttribute("data-font-size") == "lg") {
		breakpointToDesktopNav = large;
	} else if (document.documentElement.getAttribute("data-font-size") == "nm") {
		breakpointToDesktopNav = normal;
	}
}